import React, { Component, Suspense } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import fetchIntercept from 'fetch-intercept';
import C from './utils/constants';

import theme from './theme';
import { history } from './utils/history';
import axios from 'axios';

import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './assets/scss/index.scss';

/* Admin Related imports START */
// Layouts
const DefaultLayout = React.lazy(() => import('./layouts/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Login'));
const ForgotPassword = React.lazy(() => import('./views/ForgotPassword'));
const ResetPassword = React.lazy(() => import('./views/ResetPassword'));
/* Admin Related imports END */

/* Front Related imports START */
// Layouts
const DefaultFrontLayout = React.lazy(() => import('./layouts/DefaultFrontLayout'));
/* Front Related imports END */

class App extends Component {
    constructor(props) {
        super(props);
        axios.interceptors.response.use(function (response) {
            return response;
        }, function (error) {
            if (401 === error.response.status) {
                localStorage.removeItem("adminuser")
                history.push(process.env.PUBLIC_URL + '/admin/');
            } else {
                return Promise.reject(error);
            }
        });

        fetchIntercept.register({
            request: function (url, config) {
                return [url, config];
            },
            requestError: function (error) {
                return Promise.reject(error);
            },
            response: function (response) {
                if (response.status === 401) {
                    localStorage.removeItem("adminuser")
                    history.push(process.env.PUBLIC_URL + '/admin/');
                } else if(response.status === 101) {
                    history.push(process.env.PUBLIC_URL + '/admin/dashboard');
                } else {
                    return response;
                }
            },
            responseError: function (error) {
                return Promise.reject(error);
            }
        });

        axios.interceptors.response.use(function (response) {
            if (response.status === 401) {
                localStorage.removeItem("adminuser")
                history.push(process.env.PUBLIC_URL + '/admin/');
            } else if(response.status === 101) {
                history.push(process.env.PUBLIC_URL + '/admin/dashboard');
            } else {
                return response;
            }
          }, function (error) {
            // Any status codes that falls outside the range of 2xx cause this function to trigger
            // Do something with response error
            return Promise.reject(error);
          });
    }
    loading = () => (
        <div id="loading">
            <div className="loading-center">
                <div className="outer"></div>
                <div className="inner"></div>
            </div>
        </div>
    )

    render() {
        return (
            <ThemeProvider theme={theme}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Router history={history} basename={process.env.PUBLIC_URL}>
                        <Suspense fallback={this.loading()}>
                            <Switch>
                                {/* Admin Routes START */}
                                <Route exact path={`${process.env.PUBLIC_URL}/admin/login`} render={(props) => {
                                    return <Login {...props} name="Login" title={`Login | ${C.SITE_NAME}`} />;
                                }} />

                                <Route exact path={`${process.env.PUBLIC_URL}/admin/forgot-password`} render={(props) => {
                                    return <ForgotPassword {...props} name="ForgotPassword" title={`ForgotPassword | ${C.SITE_NAME}`} />;
                                }} />

                                <Route exact path={`${process.env.PUBLIC_URL}/admin/reset-password/:code`} render={(props) => {
                                    return <ResetPassword {...props} name="ResetPassword" title={`ResetPassword | ${C.SITE_NAME}`} />;
                                }} />

                                <Route path={`${process.env.PUBLIC_URL}/admin`} render={props => <DefaultLayout {...props} />} />
                                {/* Admin Routes END */}

                                {/* Client Routes START */}
                                <Route path={`${process.env.PUBLIC_URL}/`} render={props => <DefaultFrontLayout {...props} />} />
                                {/* Client Routes END */}

                            </Switch>
                        </Suspense>
                    </Router>
                </MuiPickersUtilsProvider>
            </ThemeProvider>
        );
    }
}

export default App;
