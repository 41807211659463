export default {
    // ADMIN_API_URL: 'http://siteproofs.com/projects/custom/incident-response-playbook-backend/public/api/',
    // CLIENT_API_URL: 'http://siteproofs.com/projects/custom/incident-response-playbook-backend/public/front/',
    // USER_PROFILE_IMAGE_PATH: 'http://siteproofs.com/projects/custom/incident-response-playbook-backend/assets/profile/',
    // USER_PROFILE_THUMB_IMAGE_PATH: 'http://siteproofs.com/projects/custom/incident-response-playbook-backend/assets/profile/thumb/',
    // CLIENT_IMAGE_PATH: 'http://siteproofs.com/projects/custom/incident-response-playbook-backend/files/client/',
    // ADMIN_USER_IMAGE_PATH: 'http://siteproofs.com/projects/custom/incident-response-playbook-backend/assets/profile/',
    // IMAGE_URL: 'http://siteproofs.com/projects/custom/incident-response-playbook-backend/files/client/',
    // UPLOADED_FILE_PATH: 'http://siteproofs.com/projects/custom/incident-response-playbook-backend/storage/app/',
    // SITE_URL: 'http://siteproofs.com/projects/custom/incident-response-playbook/',

    ADMIN_API_URL: 'https://www.accumepartnerssurveys.com/incident-response-playbook-backend/public/api/',
    CLIENT_API_URL: 'https://www.accumepartnerssurveys.com/incident-response-playbook-backend/public/front/',
    USER_PROFILE_IMAGE_PATH: 'https://www.accumepartnerssurveys.com/incident-response-playbook-backend/assets/profile/',
    USER_PROFILE_THUMB_IMAGE_PATH: 'https://www.accumepartnerssurveys.com/incident-response-playbook-backend/assets/profile/thumb/',
    CLIENT_IMAGE_PATH: 'https://www.accumepartnerssurveys.com/incident-response-playbook-backend/files/client/',
    ADMIN_USER_IMAGE_PATH: 'https://www.accumepartnerssurveys.com/incident-response-playbook-backend/assets/profile/',
    IMAGE_URL: 'https://www.accumepartnerssurveys.com/incident-response-playbook-backend/files/client/',
    UPLOADED_FILE_PATH: 'https://www.accumepartnerssurveys.com/incident-response-playbook-backend/storage/app/',
    SITE_URL: 'https://www.accumepartnerssurveys.com/',


    AUTOSAVE_TIME: 10000, // 10 Seconds
    GET_DATA_INTERVAL_AFTER_IMAGE_UPDATE: 3000, // 3 Seconds 
    DEFAULT_ERROR_MSG: 'Internal server process error. Try again later.',
    CONNECTION_ERROR_1: 'Oops! Something went wrong!',
    CONNECTION_ERROR_2: 'Problem with internet connection. Please make sure that your device is not switched to airplane mode.',
    SITE_NAME: 'IRP - Accume Partners',
    VALID_IMAGE_EXTENSION: [".jpg", ".jpeg", ".png"],
    REC_PER_PAGE: 10,
};
